import './autoCs.scss'

function AgreementFXD () {

  return (
    <div className='box1'>
      <h1>用户注册协议</h1>
      <div style={{ textAlign: 'left' }}>
        <p>
          <h2>【重要提示】</h2>
          <br />1、本平台由南宁市金沙小额贷款有限公司（下统称“我们或公司”）运营，依据本协议的规定为您（以下简称“平台用户”、“用户”或“您”）提供服务，本协议通过数据电文的方式订立，在您和我们之间具有合同上的法律效力，适用于您在本平台下的所有活动。我们在此特别提醒您认真阅读、充分理解本协议各条款，特别是其中所涉及的免除及限制我们责任的条款、对注册用户权利限制的条款、加粗条款等。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册本平台或使用本平台于本协议下所提供的一切服务。您一经注册或使用本平台服务即视为对本协议全部条款已充分理解并完全接受。若您不接受以下条款，请您立即停止注册或主动停止使用平台提供的服务。
<br />2、只要您进行勾选、点击“确认”或“同意”、“申请”等按钮、亦或提交信息、完成注册时，即表明您在注册前已经进行认真阅读并同意无条件接受本协议，或您使用平台提供的任一服务时，即表示您已同意我们按照本协议来合法使用、查询、授权和保护您的个人电子信息。
<br />定义：
<br />1、平台：是指我们为您提供服务的相应网站、移动客户端以及以其他形式提供的操作环境（包括未来技术发展出现的新的形态）。
<br />2、平台服务或服务：是指基于平台所有者/运营者基于平台向您提供的贷超服务（指贷款导流推荐服务，下同），具体以平台当时提供的服务内容、推送的消息或页面允许的操作为准。
<br />3、本协议：本协议内容包括协议正文、隐私权政策及平台已经发布或将来可能发布的各类规则、公告或通知。
<h3>一、账户注册及使用</h3>
          <br />1、本平台仅限于持有中国大陆（不包括香港特区、澳门特区及台湾地区）有效身份证件的18周岁以上且具有完全民事行为能力的自然人注册使用，且不向在校学生提供服务。如您不符合资格，请勿注册或使用，否则我们有权随时终止您的用户资格。
<br />2、使用我们平台服务前，您应完成手机号码实名认证，并确保系您本人使用，否则我们有权拒绝向您提供服务；您不得将在平台注册获得的账户借给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。
<br />3、您的注册账户与您手机号码绑定，您注册了平台账户则视为平台用户，建议您务必保管好您的平台账户（手机号码），并确保您在每个上网时段结束时退出登录并以正确步骤离开平台。注册账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，平台并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。
<br />4、您设置的用户名不得违反国家法律法规及平台规则关于用户名的管理规定，否则平台可回收您的用户名。用户名的回收不影响您以手机号码登录平台并使用平台服务。
<h3>二、服务内容及要求</h3>
          <br />1、我们平台展示有各合作方的贷款产品/服务，我们仅提供导流推荐服务（即作为平台协助您向各合作方申请其贷款产品/服务），实际贷款产品/服务由各合作方根据自身的规则独立审核并提供服务，您应特别关注各产品/服务的说明及规则，自行甄别风险。
<br />2、我们基于以下前提或条件为您提供服务，您明确知悉并授权同意：
<br />（1）我们仅为符合本协议及平台发布的各项规则等文件所规定之条件及遵守相关约定的您提供服务。
<br />（2）您同意根据本协议注册/认证的我们账户仅限您本人使用，若平台以您的手机号码作为登录账户，您承诺手机号码的实名认证已完成且由本人实际使用，不得出借、出租给任何第三方，不得填列非本人信息申请产品/服务。
<br />（3）在您使用我们提供的各类贷超服务过程中，您自愿根据我们服务的要求向我们或合作第三方提供所需的资料，具体所需提供的资料以页面显示要求为准，可能包括一种或多种以下数据：姓名、身份证号码、贷款需求、工作年限、期望贷款金额区间、公积金查询等，并授权我们就您提供的前述资料及我们在服务过程中获取的您的其他数据进行收集、存储及分析使用。
<br />（4）部分您所申请的合作方服务将会使用到您的公积金、社保等信息，我们会通过页面明确通知并征求您的意见，若您同意，为协助您完成申请，您授权我们按合作方的要求整理、加工、调取、传输您在我们平台留存的该等数据，若您拒绝，有可能无法申请该类产品。
<br />3、您同意，我们有权在提供服务过程中以各种方式在平台页面上投放商业性广告或其他任何类型的商业信息，并且，您同意接受平台通过短信、电话、电子邮件或其他方式向您发送产品广告或其他相关商业推广信息。
<br />4、当您登录我们平台并选择合作方产品/服务后，有两种不同类型的贷款申请方式：（1）您选择产品服务后会自动跳转到我们合作方H5页面，该等页面、平台由我们合作方控制和使用，您应了解并遵守合作方的使用规则及隐私政策，同时合作方可能会要求您注册、登录后方能使用其服务，此时您授权我们可以向合作方传输您在我们平台留存的基本信息为您在合作方平台进行自动注册、登录；（2）您选择产品/服务后，我们会直接根据合作方的要求向您明示需要提供的资料和信息，你根据我们的要求填列及补充，并在您授权并确认后受托向合作方申请，此时我们将收集、存储、查询、使用您的相关信息，并严格遵守《隐私政策》。
<h3>三、您的权利与义务</h3>
          <br />1、您应遵循诚实守信、责任自负、风险自担的原则承担使用平台服务所产生的风险，使用服务应当同时具备以下条件：
<br />（1）具有完全民事权利和民事行为能力，能够独立承担民事责任的自然人、法人或其他组织；
<br />（2）按照平台要求进行实名注册；
<br />（3）相应法律法规及平台规定、及平台有关协议约定的其他条件。
<br />2、您依照注册页面提示填写信息、阅读并同意本协议且完成手机号码认证后，方可正式获得平台认证并使用平台相应服务。除非法律明文规定、司法裁定，用户账户仅限本人使用，不得以任何方式转让，否则由此产生的一切责任均由您本人承担。
<br />3、您应保证提供的用户信息及产品申请资料真实、准确、完整、合法、有效，如有变更，应及时通过客服电话通知我们并按照我们的要求办理变更手续。如您未按照我们的规定提交相关真实、准确、完整、有效、合法的信息，或者我们有合理的理由怀疑您提交的信息为错误、虚假、违法或不完整的，我们有权拒绝为您提供服务，对此我们不承担任何责任。同时，因此所产生的任何直接或间接的支出、损失、费用、处罚将由您承担。若由于您未及时更新用户信息，而导致平台相关服务无法提供或服务的有关流程及操作发生任何错误，您不得以此为由取消交易、拒绝支付交易款项和服务手续费或采取其它不当行为，并将独立承担因此产生的所有责任和后果。
<br />4、您不得通过平台发布违反法律、法规、政策或破坏性的信息，不得以任何非法目的或途径使用平台服务，不得干扰平台系统的正常运作。
<br />5、您知悉我们仅为导流推荐平台，贷款产品/服务由我们合作的第三方审核和提供，涉及的贷款纠纷由您与该等合作第三方自行处理。您或我们受托提交给第三方的资料、信息由合作方收集、保存、管理与使用，你自愿遵守合作方的使用规则及隐私政策，若因该等第三方原因导致损失的，您可以向合作方追偿，必要时，我们将协助您与合作方沟通协调。
<br />6、您同意，确保账户安全是您的责任。通过个人账户/手机号码登录所进行的一切操作、活动，发布的一切言论，都视为您本人的行为及其真实意图的表达，所有损失及法律后果由您本人自行承担。
<h3>四、我们的权利与义务</h3>
          <br />1、我们有权根据您所提供注册资料的情况（包括真实性、准确性、完整性、有效性、合法性等）以及我们合作方产品/服务的规定，向您推荐产品或服务，但具体产品/服务的选择，由您自行确定。
<br />2、我们有权基于单方独立判断，在认为可能发生危害交易安全、您违反或有可能违反本协议约定等情形时，不经通知而先行暂停、中断或终止向您提供本协议项下的全部或部分服务（包括收费服务），且无需对您或任何第三方承担任何责任及损失。前述情形包括但不限于：
<br />（1）我们认为您提供的资料不具有真实性、准确性、有效性或完整性、合法性；
<br />（2）我们发现异常交易，有违法违规或有疑义，亦或有可能侵犯第三方权益、可能泄露您或其他第三方个人信息情况；
<br />（3）我们认为注册账户涉嫌洗钱、套现、传销、被冒用或我们认为有风险的情形；
（<br />4）我们认为您存在或可能存在违反本协议规则及原则情况；
<br />3、我们有权基于交易安全、信誉、信息安全等方面的考虑而设定涉及交易、服务的相关事项，包括但不限于交易限额、交易次数、服务次数等。
<br />4、您存在恶意诋毁或损害平台声誉、攻击平台相关系统、利用平台用户身份从事违反国家法律法规活动等行为之一的，我们有权单方面终止您的权利，并保留追究您法律责任的权利。
<br />5、您明确知悉并同意，我们不对您的交易实现做任何形式的担保和承诺。
<br />6、我们有权对您采取措施防范欺诈行为，发现欺诈行为或其他损害或可能损害其他平台用户利益的情形，我们有权及时终止该您继续使用平台活动并要求您承担平台因此而导致的一切支出或损失。
<br />7、我们有权根据业务发展需要、国家法律法规、监管等的要求修改本协议或根据本协议制定、修改平台各类具体规则并在本平台相关系统板块发布，不再另行向您做个别通知，您有权选择继续或者停止使用本平台的服务（若需停用，请登录公司官网及时与公司客服联系）。若您在本协议及具体规则内容变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议和具体规则内容，也将遵循修改后的协议和具体规则使用本平台的服务，同时就您在协议和具体规则修订前通过本平台使用的服务、进行的交易及其效力，视为您已同意并已按照本协议及有关规则进行了相应的授权和追认。若您不同意修改后的协议内容，您应停止使用本平台的服务，我们也有权不经告知即中止、 终止本协议或者限制您进入本平台的全部或者部分板块且不承担任何法律责任，但该中止、终止或限制行为并不能豁免您在本平台已经进行的交易、服务项下所应承担的义务。
<br />五、内容规范
<br />1、本条所述内容是指您使用平台服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、昵称、姓名、身份证号码、手机号码、贷款需求、住址等注册信息及认证资料、产品申请资料，或发布的评论、评论的评论，以及其他使用账号或本服务所产生的内容。
<br />2、您承诺您向我们提供的全部资料真实、完整、合法、有效，不存在伪造、变造、隐瞒或误导。
<br />3、您不得利用注册账号或本平台、本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：
<br />1）反对宪法所确定的基本原则的；
<br />（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
<br />（3）损害国家荣誉和利益的；
<br />（4）煽动民族仇恨、民族歧视，破坏民族团结的；
<br />（5）破坏国家宗教政策，宣扬邪教和封建迷信的；
<br />（6）散布谣言，扰乱社会秩序，破坏社会稳定的；
<br />（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
<br />（8）侮辱或者诽谤他人，侵害他人合法权益的；
<br />（9）不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；
<br />（10）含有法律、行政法规禁止的其他内容的信息。
<br />4、您不得利用注册账号或本平台、本服务制作、上载、复制、发布、传播如下干扰平台正常运营，以及侵犯其他用户或第三方合法权益的内容：
<br />（1）含有辱骂、恐吓、威胁内容的；
<br />（2）含有骚扰、垃圾广告、恶意信息、诱骗信息的；
<br />（3）涉及他人隐私、个人信息或资料的；
<br />（4）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
<br />（5）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
<h3>六、协议的修订及通知</h3>
          <br />1、我们可能随时会根据国家法律、政策的规定或提升服务的需要、您的建议、享有的服务情况对本协议进行修改、补充或变更，由于使用平台服务的用户基数较为庞大，因此，如发生修改、补充或变更，我们将选择在官网或者官方微博、微信等平台，以公告的方式予以公布而不再另行单独通知，该等变更将在公布时即时生效。若您在本协议变更后继续访问平台，或使用平台提供的任一服务，您确认这代表您已充分阅读、理解并接受修改后的本协议并受之约束。
<br />2、平台对于您的通知或其他关于您使用平台账户及服务的通知，您同意平台可通过平台公告、电子邮件、手机短信、无线通讯装置等电子方式或常规的信件传递、电话通讯等方式进行，该等通知于发送之日视为已送达收件人。因信息传输等原因导致您未在前述通知发出当日收到该等通知的，我们不承担责任。
<h3>七、除外责任</h3>
          <br />1、因您自身的原因导致的任何损失或责任，由您自行负责，我们不承担任何责任。我们不承担责任的情形包括但不限于：
<br />（1）您未按照本协议或平台公布的规则进行操作导致的任何损失或责任；
<br />（2）您向平台提交的资料虚假、有错误、违法或不完整、不准确的。
<br />（3）您违反与我们合作第三方的产品规则、履约要求的。
<br />（4）您将您账户出借、泄露给任何第三方，不论是否故意或过失。
<br />2、在任何情况下，对于您使用涉及由合作第三方提供相关服务的责任由该第三方承担，我们不承担任何责任。该等情形包括但不限于：
<br />（1）因您使用合作第三方提供的贷款产品/服务而产生的任何损失或责任。
<br />（2）因您向合作第三方提供产品/服务所需资料而导致的信息泄露产生的责任。
<br />（3）因合作第三方的违规行为导致您的损失。
<br />（4）因银行、第三方支付公司、数据公司、服务合作方等其他第三方未按照您和/或平台指令进行操作引起的任何损失或责任；
<br />（5）因银行、第三方支付公司、数据公司、服务合作方等其他第三方原因导致资金未能及时到账而引起的任何损失或责任；
<br />（6）因其他第三方的行为或原因导致的任何损失或责任。
<br />3、平台上的服务涉及由合作第三方所有、控制或者运营的页面、APP、网站（以下统称“第三方网站”）。我们会做上架审查，但不能完全保证第三方网站上任何信息的真实性和有效性。您应审查并自行甄别相关风险，同时有任何风险应第一时间通知我们。
<h3>八、隐私条款</h3>
          <br />1、我们将以高度的勤勉、审慎义务对待您的个人信息，尊重并保护所有使用平台服务用户的个人隐私，并使用各种制度、安全技术和程序等措施等来严格保护您的个人信息安全，保护您的个人信息不被未经授权的访问、篡改、披露或破坏。除本协议及具体授权条款约定外，在未征得您事先许可的情况下，我们不会将您个人信息对外披露或向第三方提供。
<br />2、我们收集信息的范围仅限于我们根据您贷款需求推荐贷款产品之目的，以给您提供更准确、更有个性化的服务。我们将按照本协议及其他相关授权文件中具体授权条款的规定收集、存储、保护、使用和共享您的个人信息，您应在注册或使用各项服务前完整地阅读具体授权条款、隐私政策，以帮助您了解维护自己权益的方式。
<br />3、您在同意本协议或使用平台任意服务之时，即视为您已同意我们按照具体授权条款来合法使用和保护您的个人信息。
<br />4、具体隐私政策见平台文件《隐私政策》
<h3>九、责任范围及限制</h3>
          <br />1、除相关法律、法规明确规定外，我们仅对本协议中列明范围内的责任负责。
<br />2、因以下情况，致使平台没有正确执行或无法履行您在平台提交的操作指令，我们不承担任何责任：
<br />（1）指令信息不明、存在乱码或不完整等；
<br />（2）您所拥有的产品发生失效、过期、终止、作废等情况；
<br />（3）不可抗力或意外事件（包括但不限于病毒、黑客攻击、系统错乱等）；
<br />（4）其它我们无过失的情况。
<br />（5）您须妥善保存用户名、密码或认证手机号，对您保管不善造成的损失，我们不对此承担任何责任。您亦不得以其密码等信息丢失否认已订立的合同的效力。
<br />3、如果您因在第三方设备、移动通讯设备、公共共享环境或电脑被远程监控的情况下使用平台提供的服务而造成包括但不限于信息泄露、数据丢失以及财产损失的，我们不对此承担任何责任。
<br />4、如发生不可抗力事件、病毒、黑客攻击、第三方服务瑕疵、政府行为以及现有科学技术限制等原因导致的服务中断、数据丢失以及其它的损失和风险，不应视为我们违约，我们无需为此承担任何违约责任。
<br />5、平台服务不允许您使用他人手机号码注册，否则，因此产生的法律责任将由您本人承担。您在平台使用第三人手机，须事先获得第三人同意。一旦输入正确的验证码，我们即视您的行为已获得第三人同意。我们不对您或第三人因此产生的损失承担任何责任，并且如该项操作造成我们损失的，我们将保留追索的权利。
<br />6、任何情况下，我们对本协议所承担的违约赔偿责任总额不超过我们已向您收取的服务费用总额。
<h3>十、风险提示</h3>
          <br />1、您了解并认可，任何通过平台进行的交易并不能避免以下风险的产生，我们不能也没有义务为如下风险负责：
<br />（1）政策监管风险：因宏观政策、财政政策、货币政策、监管导向、行业政策、地区发展政策等因素引起的无法实现交易的风险；
<br />（2）违约风险：因其他交易方无力或无意愿按时足额履约，您有可能遭受损失；
<br />（3）操作风险：任一方使用的计算机系统被第三方侵入、宕机，或火灾、恐怖攻击灾难发生等，您有可能遭受损失。
<br />（4）因您的过错导致的任何损失，该过错包括但不限于：决策失误、操作不当、遗忘或泄露密码、密码被他人破解、您委托他人代理交易时他人恶意或不当操作而造成的损失。
<br />2、基于平台的业务性质，我们并非平台贷款服务的提供方，仅作导流推荐服务，因此我们不对平台上任何用户及/或任何交易提供任何担保或条件，无论是明示、默示或法定的。我们不能也不试图对用户发布的信息进行控制，对该等信息，我们不承担任何形式的证明、鉴定服务。我们不能完全保证合作第三方平台内容的真实性、准确性、完整性和有效性，并且无需承担任何由此引起的法律责任。您依赖于您的独立判断进行交易，您应对其作出的判断承担全部责任。
<br />3、以上并不能揭示您通过平台所进行交易的全部风险及市场的全部情形。您在做出交易决策前，应全面了解相关交易，谨慎决策，并自行承担全部风险。
<br />4、特别申明：我们系导流推荐平台，仅为遵守本协议并合法使用的您提供服务。
<h3>十一、知识产权保护条款</h3>
          <br />1、平台上提供的所有内容（包括但不限于文字、音频、视频、图片、图表、广告、平台向您发送的电子邮件等）的知识产权归我们及相关权利人所有；您在平台发布的任何文字、创作、图片等，您授权我们都有永久的免费使用权。
<br />2、除另有声明外，我们提供平台服务时所依托的软件著作权和专利权归我们所有。
<br />3、未经我们授权，您不得以任何方式擅自修改、复制、存储、传送、抄袭、分发上述内容。
<br />4、未经我们授权，您不得将平台包含的任何内容用作商业或其它公开用途。任何未经我们授权对平台内容的使用均属于违法行为。
<h3>十二、法律适用</h3>
          <br />1、本协议的订立、生效、履行、解释以及由此产生的所有事项均适用中华人民共和国大陆法律（不包含港澳台法律法规、冲突法等）。
<br />2、凡因履行本协议所引起的任何争议，双方同意首先以友好协商的方式解决。协商不成的，任一方均可将争议提交仲裁委员会，按照仲裁委当时有效的仲裁规则进行仲裁，仲裁裁决是终局的，对双方均有约束力。
<h3>十三、本协议中的部分条款无效，不影响其它条款的效力。若上述无效条款构成本协议经济目的之条款或核心条款，则该条款应被尽可能接近各方意图、能够保留本协议要求的经济目的且为有效的新条款所取代。在此情况下，本协议的其它条款仍然有效。
</h3>
        </p>
      </div>
    </div>
  )
}

export default AgreementFXD
import MainXJ from './Main_xj'
import MainOPPO from './Main_oppo'
import Privacy from './Privacy' //借条借钱花
import Privacy_wy from './Privacy_wy' //无忧借钱贷款
import Privacy_fxd from './Privacy_fxd' //应急借钱放心贷
import Agreement from './Agreement' //借条借钱花
import Agreement_fxd from './Agreement_fxd' //应急借钱放心贷
import Agreement_wy from './Agreement_wy' //无忧借钱贷款
import Agreement_kfq from './Agreement_kfq' //无忧借钱贷款
import Agreement_xj from './Agreement_xj'
import Agreement_ayjq from './Agreement_ayjq'
import Agreement_ayjq_oppo from './Agreement_ayjq_oppo'
import Agreement_yj_oppo from './Agreement_yj_oppo'
import Agreement_yjdkh from './Agreement_yjdkh'
import Agreement_fxd_xm from './Agreement_yhjqfxd_xm'
import AgreementXJXiaoyi from './Agreement_xjxiaoyi'
import AgreementJinDing from './Agreement_jinding_ry'
import AgreementHaoYong from './Agreement_haoyong'
import AgreementHaoYongVIVO from './Agreement_haoyong_vivo'
import AgreementYouJieYouHuan from './Agreement_youjieyouhuan'//有借有还 和 放心贷款
import Agreementhuahua from './Agreement_huahua'
import Agreement_hainanyiyu from './Agreement_海南一遇'
import Agreement_doulai from './Agreement_抖来贷款花软件'
import Agreement_10131 from './Agreement_10131'
import Agreement_10132 from './Agreement_10132'
import Agreement_10133 from './Agreement_10133'

import Privacy_kfq from './Privacy_kfq' //无忧借钱贷款
import Privacy_xj from './Privacy_xj'
import Privacy_ayjq from './Privacy_ayjq'
import Privacy_ayjq_oppo from './Privacy_ayjq_oppo'
import Privacy_yj_oppo from './Privacy_yj_oppo'
import Privacy_yjdkh from './Privacy_yjdkh'
import Privacy_fxd_xm from './Privacy_yjjqfxd_xm'
import PrivacyXJXiaoyi from './Privacy_xjxiaoyi'
import PrivacyJDJDJYQ from './Privacy_jinding_jdjyq'
import PrivacyJDRY from './Privacy_jinding_ry'
import PrivacyHaoYong from './Privacy_haoyong'
import PrivacyHaoYongVIVO from './Privacy_haoyong_vivo'
import PrivacyYouJieYouHuan from './Privacy_youjieyouhuan'
import PrivacyFangXinDaiKuan from './Privacy_fangxindaikuan'
import Privacyhuahua from './Privacy_huahua'
import Privacy_hainanyiyu from './Privacy_海南一遇'
import Privacy_doulai from './Privacy_抖来贷款花软件'
import Privacy_mao from './Privacy_借钱贷款猫软件'
import Privacy_10128 from './Privacy__10128'
import Privacy_10131 from './Privacy_10131'
import Privacy_10132 from './Privacy_10132'
import Privacy_10133 from './Privacy_10133'
import Agreement_mao from './Agreement_借钱贷款猫软件'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App () {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route index element={<MainWDK />} /> */}
        {/* <Route index element={<MainXJ />} /> */}
        {/* <Route path='/home' element={<MainXJ />} /> */}
        <Route index element={<MainOPPO />} />
        <Route path='/home' element={<MainOPPO />} />
        <Route path='/agreement' element={<Agreement />} />
        <Route path='/agreement_fxd' element={<Agreement_fxd />} />
        <Route path='/agreement_wy' element={<Agreement_wy />} />
        {/* <Route path='/agreement_kfq' element={<Agreement_kfq />} /> */}
        <Route path='/agreement_xj' element={<Agreement_xj />} />
        <Route path='/agreement_ayjq' element={<Agreement_ayjq />} />
        <Route path='/agreement_ayjq_oppo' element={<Agreement_ayjq_oppo />} />
        <Route path='/agreement_yj_oppo' element={<Agreement_yj_oppo />} />
        <Route path='/agreement_yjdkh' element={<Agreement_yjdkh />} />
        {/* <Route path='/agreement_fxd_xm' element={<Agreement_fxd_xm />} /> */}
        <Route path='/agreement_xj_xiaoyi' element={<AgreementXJXiaoyi />} />
        <Route path='/agreement_jinding' element={<AgreementJinDing />} />
        <Route path='/agreement_haoyong' element={<AgreementHaoYong />} />
        <Route path='/agreement_haoyong_vivo' element={<AgreementHaoYongVIVO />} />
        <Route path='/agreement_youjieyouhuan' element={<AgreementYouJieYouHuan />} />
        <Route path='/agreement_huahua' element={<Agreementhuahua />} />
        <Route path='/agreement_hainanyiyu' element={<Agreement_hainanyiyu />} />
        <Route path='/agreement_doulai' element={<Agreement_doulai />} />
        <Route path='/agreement_mao' element={<Agreement_mao />} />
        <Route path='/agreement_10131' element={<Agreement_10131 />} />
        <Route path='/agreement_10132' element={<Agreement_10132 />} />
        <Route path='/agreement_10133' element={<Agreement_10133 />} />

        <Route path='/privacy' element={<Privacy />} />
        <Route path='/privacy_wy' element={<Privacy_wy />} />
        <Route path='/privacy_fxd' element={<Privacy_fxd />} />
        {/* <Route path='/privacy_kfq' element={<Privacy_kfq />} /> */}
        <Route path='/privacy_xj' element={<Privacy_xj />} />
        <Route path='/privacy_ayjq' element={<Privacy_ayjq />} />
        <Route path='/privacy_ayjq_oppo' element={<Privacy_ayjq_oppo />} />
        <Route path='/privacy_yj_oppo' element={<Privacy_yj_oppo />} />
        <Route path='/privacy_yjdkh' element={<Privacy_yjdkh />} />
        {/* <Route path='/privacy_fxd_xm' element={<Privacy_fxd_xm />} /> */}
        <Route path='/privacy_xj_xiaoyi' element={<PrivacyXJXiaoyi />} />
        <Route path='/privacy_jinding_ry' element={<PrivacyJDRY />} />
        <Route path='/privacy_jinding_jdjyq' element={<PrivacyJDJDJYQ />} />
        <Route path='/privacy_haoyong' element={<PrivacyHaoYong />} />
        <Route path='/privacy_haoyong_vivo' element={<PrivacyHaoYongVIVO />} />
        <Route path='/privacy_youjieyouhuan' element={<PrivacyYouJieYouHuan />} />
        <Route path='/privacy_fangxindaikuan' element={<PrivacyFangXinDaiKuan />} />
        <Route path='/privacy_huahua' element={<Privacyhuahua />} />
        <Route path='/privacy_hainanyiyu' element={<Privacy_hainanyiyu />} />
        <Route path='/privacy_doulai' element={<Privacy_doulai />} />
        <Route path='/privacy_mao' element={<Privacy_mao />} />
        <Route path='/privacy_10128' element={<Privacy_10128 />} />
        <Route path='/privacy_10131' element={<Privacy_10131 />} />
        <Route path='/privacy_10132' element={<Privacy_10132 />} />
        <Route path='/privacy_10133' element={<Privacy_10133 />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App

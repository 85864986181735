import './autoCs.scss'

function PrivacyYJJQFXDXM () {

  return (
    <div className='box1'>
      <h1>隐私政策</h1>
      <h2>前 言</h2>
      <div style={{ textAlign: "left" }}>
        <p>
          APP：借钱贷款猫
        <br />
        灵川县裕丰小额贷款股份有限公司（以下统称“我们”）一向重视保护使用我们旗下产品/服务（以下统称“我们服务”）用户（以下统称“用户”或“您”）的隐私。我们深知个人信息对您而言的重要性，我们将通过本政策向您说明我们的收集目的、方式、范围及如何收集、存储、保护、使用您的信息，其中要点如下：
        <br />
          1、为了便于您了解您在使用我们APP及相关服务时，我们需要收集的信息目的、方式、范围，我们将结合相关服务向您阐述。
        <br />
         2、为了向您提供服务所需，我们会按照合法、正当、必要的原则收集您的信息。
        <br />
          3、如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以协议、具体场景下的文案、弹窗提示、抽查等形式征得您的同意或确认第三方已经征得您的同意。
        <br />
          4、如果为了向您提供服务而需要从第三方获取您的信息，我们将严格遵守相关法律法规与监管要求，通过要求您提供相关必要信息、点击确认或弹窗等形式征得您的明示同意，并且对您的信息采取保护措施。
        <br />
          5、您可以通过本政策介绍的方式访问和管理您的信息、个人信息更正/删除、撤回/撤销、注销账户或进行投诉举报。
        <br />
          您可以根据以下索引阅读相应章节，进一步了解本政策的具体约定：
        </p>
      </div>
      <h2>目 录</h2>
      <div style={{ textAlign: "left" }}>
        一、我们收集您信息的目的、方式和范围 <br />
        二、我们如何使用您的信息（包括分享、转让、披露等） <br />
        三、我们如何保留、储存和保护信息 <br />
        四、如何管理您的信息 <br />
        五、第三方贷款产品/服务 <br />
        六、年龄限制 <br />
        七、通知和修订 <br />
        八、其他 <br />
        九、如何联系我们 <br />
        <h2>一、我们收集您信息的目的、方式和范围</h2>
        <p>
          我们收集您的信息主要是为了遵守国家法律法规及监管规定（例如：进行实名制管理、履行反洗钱职责、安全管理等），以及用于向您提供服务、完成您的委托事项、保障账户安全等，以便于您和其他用户能够更安全、更容易和更满意地使用我们服务。我们的目标是在合法合规的前提下向所有符合要求的互联网用户提供安全、便捷、高效的服务，而这些信息有助于我们实现这一目标。
          <br />
          请您理解并同意，我们向您提供的产品和服务种类众多，且不同用户选择使用的具体功能、产品/服务范围存在差异，因此，基于用户使用差异，我们收集使用的个人信息类型、方式、范围等会有所不同，请以以下所述具体的产品/服务功能为准；同时由于法律、法规及商业策略的变化，本APP的功能也可能进行更迭和发展，如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将收集而来的信息用于其他目的，我们会修改隐私政策，并通过APP以合理的方式 (如弹窗等形式)向您告知，并在使用前再次征得您的同意。
        </p>
        <p>
          （一）我们仅会出于本隐私政策所述的以下目的、方式与范围，收集和使用您的相关个人信息：  <br />
  1、为您提供账号服务  <br />
  为了确认您的身份，建立您的APP账号并对账号进行验证以实现登录目的，您在注册APP账号时，您须至少向我们主动填写提供您的手机号码、验证码，我们将基于您填写的信息为您创建账号。在您后续登录APP时，您需使用您的手机号码、验证码，以便于我们验证您的身份；同时若您使用的是安卓系统，基于部分安卓系统或手机系统的要求，为向您提供便捷的登录服务，我们将以弹窗的方式向您征询短信和通话的权限，若您不同意，将无法自动读取您的短信验证码，但不影响您使用，您可以通过手动填写验证码的方式进行登录。
  为识别和验证您的用户身份，以及为保障您的金融信息安全，防止您的资金、个人信息被不法分子获取并侵害您的合法权益，在您使用期间，我们会自动收集您的设备信息<span style={{fontWeight:"bold",color:"red"}} >（包括设备型号、ANDROID ID、设备MAC地址、SIM卡的IMSI信息、设备序列号IMEI、OAID、IP地址、唯一设备识别码、设备软件版本信息、网络信息、软件安装列表、操作系统类型）、应用软件运行中的进程信息、记录您使用的服务类别、方式及相关操作信息。<br />
  IMEI、OAID、IMSI、MAC、Android ID权限获取说明：</span><br />
获取方式：用户阅读并且同意隐私政策之后，表明默许本公司获取该用户IMEI、OAID、IMSI、MAC、Android ID权限，程序自动化获取，无需用户手动。<br />
获取目的：防止黑客，灰产行业，爬虫等通过不正当途径循环注册本平台，盗取平台用户数据；这些权限也是用户的唯一标示，才能在本平台享受用户对应的服务权利；<br />
权限存储：所有用户的权限数据都保存在本公司的数据库里面，所有数据全部经过加密，严格保证用户数据不泄密。<br />
获取设备信息：读取通话状态和移动网络信息，是用于提供给用户快速登录的能力，用于一键登录app <br />
应用列表信息权限说：本平台为网络贷款服务平台，在授权用户贷款额度之前，我们需要对用户的贷款资格进行审查，我们必须了解用户的行为习惯，才能为用户授信风险模型提供数据支撑；所有数据都是经过加密保存在本公司服务器上面，严格保密用户数据安全。（所有数据在用户授权同意隐私政策前，本产品不会向第三方发送IMEI、OAID、IMSI、MAC、Android ID、应用列表等信息；）<br />
  2、为您提供第三方贷款产品的申请服务  <br />
  （1）贷款申请  <br />
  我们在平台上展示有众多合作伙伴提供的贷款产品/服务，为了让您更安全、便捷地申请APP上展示的贷款产品/服务，以及出于满足产品准入要求及满足反洗钱、反欺诈等的需要，在您通过APP申请贷款产品/服务时，您需要向我们提供身份证信息（真实姓名、身份证号码）。在此过程中，我们会通过弹窗、表单填写等方式请求您提供上述信息并实现信息收集；部分产品为进一步做实名认证、活体检测、准入等要求，会以弹窗的方式请求您开启摄像头/录音/相机权限，并通过您移动设备的摄像头及麦克风使用第三方SDK以实现上述信息的收集。此外，部分产品为了验证您提供信息的准确性和完整性，我们会在告知并取得您的授权同意后，通过API向与我们合作的第三方传输您的姓名、身份证号码、面部识别影像资料、身份证正反面图像用于多重交叉验证。如您不提供上述个人信息并开启摄像头/录音/相机权限以完成实名认证、活体检测、反欺诈等的，您将无法正常使用APP展示的贷款产品申请服务，但不影响您使用我们提供的其他服务。
  为了核验您所提供的地区信息的准确性以及检测您所申请的产品是否对您所在的地区开放申请，在您提供资料过程中，我们会请求您开启定位权限，并收集您的定位信息。如您不同意我们收集您的定位信息，请您不要开启定位权限并停止使用贷款申请服务功能。如您拒绝提供定位信息，您将可能无法正常使用部分产品的贷款申请服务，但不影响您申请使用其他产品和功能。
  为了尽量科学、全面、客观、准确地协助合作机构开展风控、确定不同用户的授信额度、识别是否符合产品准入要求，我们需要收集与您信用相关的真实、准确、全面的信息，用于协助合作机构对您进行风险审核和信用评估以及帮助您依法依约履行合同义务并向您追索到期债务。您需要通过主动填写的方式向我们提供您的个人基础信息（包括姓名、身份证号码、贷款需求、目的、月收入概况、工作城市、工作年限、学历、公积金/社保缴存时间、信用卡额度情况、逾期情况、保险投保情况、房产情况等）、紧急联系人信息（联系人姓名、电话及关系）等。如您不提供上述个人信息的，将无法满足贷款产品的申请要求，我们将无法协助您完成贷产品的申请、推荐，但不影响您使用我们提供的其他功能或服务。
  <span style={{fontWeight:"bold",color:"red"}} >此外，为了提高贷款额度、增加贷款审批通过的概率或提高个人资信情况，您可以选择提供您的公积金、社保详细信息，我们提供有公积金、社保的查询通道，并会以弹窗的方式提醒您进行授权并基于您的合法有效授权进行受托查询，您按照页面要求填写用户名和密码，届时我们将收集、存储您的用户名、密码并进行数据查询。我们会从合法持有您个人信息的数据源机构查询、存储、收集您的公积金信息（包括姓名、缴存单位、缴存信息、账户余额、缴存流水、公积金贷款信息，具体以数据源机构返回的数据为准）、社保信息（包括姓名、缴存单位、缴存信息、账户余额、缴存流水，具体以数据源机构返回的数据为准），并根据您的授权及上述目的向您指定的合作机构提供。如您不提供或不同意我们传输上述个人信息的，不影响您产品/服务的申请，但可能无法进一步提高您的贷款额度、通过概率或提供您的资信等级。</span>
  3、为您进行安全管理  <br />
  <span style={{fontWeight:"bold",color:"red"}} >为了保障您的账号安全，预防交易和资金风险，以及合作方的反欺诈审查、风控识别之要求，我们需要自动收集或记录您使用的APP服务类别、方式及设备型号、ANDROID ID、OAID、网络类型、运营商名称、Wi-Fi名称、Wi-Fi mac地址、位置信息、设备软件版本信息、设备名称、设备标识符、MAC地址、系统版本号、ROOT情况、操作行为、软件安装情况、应用软件运行中的进程信息以及其他与APP服务相关的日志信息。</span>如您不同意我们记录前述信息的，我们可能无法对您的账号进行风控验证、账号安全管理或协助您申请相关贷款产品，但不影响您使用我们提供的其他服务。
  <br />
  4、我们的客服会使用您的姓名、手机号码、身份证号码等个人信息与您核验身份。为解答您咨询的问题，我们会收集您与客户服务团队联系时提供的信息及您参与问卷调查时向我们发送的信息，并可能需查看您使用APP期间服务的记录、账号信息和交易信息，有时还需要向涉及的商户进行核实或调查。如您不同意提供前述信息的，我们可能无法为您提供客户服务，但不影响您使用我们提供的其他服务。
  5、为您发送信息  <br />
  为更好地向您提供全面的服务，您同意我们通过您的电话、短信、站内信等方式，合理地向您发送商业性服务信息、平台最新的有关管理、产品内容及其他平台认为有必要告知注册会员的服务通知。如果您不想接收我们的商业推送信息，您可以通过客服电话的方式联系我们关闭此功能。
  （二）提供第三方代码、插件（SDK）的服务方  <br />
  1、目的：为向您提供专业、便捷、安全的服务，实现数据统计、消息推送、应用分享、便捷登录、客服服务、身份核验等相关功能之目的或实现合作方产品之要求，我们会接入由第三方提供的软件开发包（SDK）以实现前述目的。我们会尽到审慎义务，对合作方获取信息的软件工具开发包（SDK）进行严格的审查，以保护数据安全。  <br />
  2、目前APP所接入的第三方SDK名称、收集/使用的个人信息范围、实现的业务功能/目的及机构名称等情况披露如下：  <br />
  第三方名称：高德软件有限公司  <br />
  使用目的：实现定位 收集个人信息：位置信息（经纬度、精确位置、粗略位置）【通过IP 地址、GNSS信息、WiFi状态、WiFi参数、WiFi列表、基站信息、信号强度的信息、蓝牙信息、传感器信息（矢量、加速度、压力）、设备信号强度信息获取】、设备标识信息（IMEI、IDFA、IDFV、Android ID、MEID、MAC地址、OAID、IMSI、硬件序列号）、当前应用信息（应用名、应用版本号）、设备参数及系统信息（系统属性、设备型号、操作系统）。基于不同的设备和系统（安卓/iOS）及系统版本，以及开发者在集成、使用我们SDK产品时决定的权限，收集的设备信息会有所不同，因此开发者应对实际收集的个人信息向用户进行说明。各SDK具体收集使用个人信息情况详见https://lbs.amap.com/agreement/sample） 隐私权政策链接：https://lbs.amap.com/pages/privacy/
  除上述个人信息外，SDK服务将根据服务提供的不同维度采集如下非个人信息类数据：SDK/API/JS代码版本、浏览器、互联网服务提供商、平台、时间戳、应用程序版本、应用分发渠道、设备型号、传感器参数、终端制造厂商、终端设备操作系统版本、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。第三方sdk名称：com.umeng(友盟)

服务类型：移动统计 SDK AppTrack，应用性能监控平台 U-APM

使用说明：用户你好，友盟sdk的使用主要是统计产品用户增长的激活和注册数据，区别用户的唯一性；用户唯一性也是用户贷款资质评判的条件。我们会严格保护用户数据，严防数据泄露。

隐私权政策链接：https://www.umeng.com/policy
  3、为了持续优化我们提供服务的稳定性、服务质量或者升级相关功能，我们可能会调整我们接入的第三方SDK并披露接入的SDK情况。我们会及时在隐私政策或相关说明、通知中向您公开说明接入第三方SDK的最新情况。
  （三）COOKIES及同类技术  <br />
  Cookie和其他同类技术（以下统称“Cookie”）是互联网中普遍使用的技术。为了帮助我们更好地了解用户行为，提供更好的用户体验，当您使用APP时，我们使用Cookie来记录信息，包括您的用户ID以及与我们服务相关的日志。有关详情，请参见 AboutCookies.org。
  请您理解，Cookie使用对APP提供服务具有至关重要的作用：例如，当您登录账号时，我们使用Cookie记录您的账号信息，为您省去重复输入信息等步骤、帮助您获得更轻松更连贯的使用体验、帮助我们判断您的账户安全；当您浏览APP时，我们使用Cookie的相关分析工具分析我们平台的加载时间、访客使用的方式、访客查看最多的信息等内容，帮助我们了解您使用我们平台的方式，从而帮助我们改进服务；Cookie还帮助我们发现服务中没有达到预期效果的部分，促使我们采取修复措施，为您打造更好用的平台。
  请您理解，我们的账号服务、金融借贷产品申请服务等服务内容必须通过使用Cookie才可得到实现。您可以选择接受或拒绝所有Cookie，也可以清除设备上的Cookie，或在您的设备允许情况下修改对Cookie的接受程度或拒绝具体类别的Cookie。如果您关闭或清除Cookie的，您仍可以使用我们平台的部分内容，但可能导致您无法使用依赖于Cookie的服务或相应功能。
  （四） 其他  <br />
  1、您主动提供或者授权我们收集的上述个人信息，将在您的账号存续期间持续授权我们使用，但您也可以随时撤回授权或联系我们删除您的个人信息。在您注销账号后，我们将停止使用并对您的个人信息进行删除或匿名化处理，法律法规另有规定的除外。
  请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了我们新开发的其他服务，基于该服务我们需要收集您的信息的，若不在上述范围内，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。此外，第三方主体会通过我们平台向您提供服务。当您进入第三方主体运营的服务页面时，请注意相关服务由第三方主体向您提供。涉及到第三方主体向您收集个人信息的，建议您仔细查看第三方主体的隐私政策或协议约定。
  请特别注意，如您在我们服务中其他用户可见的公开区域内上传或发布的信息中、您对其他人上传或发布的信息作出的回应中公开您的个人信息，该等信息可能会被他人收集并加以使用。当您发现我们的用户不正当地收集或使用您或他人的信息时，请与我们取得联系（联系方式详见我们官网、APP等）。  <br />
  2、请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：  <br />
  （1）与我们履行法律法规规定的义务相关的；  <br />
  （2）与国家安全、国防安全直接相关的；  <br />
  （3）与公共安全、公共卫生、重大公共利益直接相关的；  <br />
  （4）与犯罪侦查、起诉、审判和判决执行等直接相关的；  <br />
  （5）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；  <br />
  （6）所收集的个人信息是您自行向社会公众公开的；  <br />
  （7）从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；  <br />
  （8）根据您的要求签订和履行合同所必需的；  <br />
  （9）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置服务的故障；  <br />
  （10）法律法规规定的其他情形。  <br />
  <h2>二、我们如何使用您的信息（包括共享、转让、披露等）</h2>
  （一）共享  <br />
  除以下情形外，未经您同意，我们不会与我们之外的任何第三方分享您的信息：  <br />
  （1）为协助您申请合作机构提供的产品/服务，满足准入要求、实现交易目的。只有共享您的信息，才能申请您需要的产品或服务，例如：基于您的申请与授权，向您申请的借款服务提供方共享您的基础信息、设备信息、身份信息；基于您的授权及产品准入要求查询您的公积金信息、社保信息等。
  <br />（2）为了开展身份验证活动、活体检测活动、反欺诈活动、银行卡鉴权活动，我们可能基于您的授权及设定，将您的真实姓名、手机号码、身份证信息（身份证号码和有效期、身份证证件正反面影像件、身份证发证机关）、银行卡号、本人面部识别影像资料、唯一设备识别号、IP地址、基站ID等信息提供给三方身份验证服务提供商，以获取他们的支持，这些支持包括但不限于提供技术基础设施服务、提供产品内或产品链接后的功能型服务，分析我们线上服务的使用方式、衡量广告和服务的有效性、提供客户服务和调查与分析。
  <br />（3）为了给您提供更专业、安全的核心业务功能和附加业务功能，我们的产品中会接入由第三方提供的软件开发包（SDK）以实现前述目的。在通过第三方SDK为您提供相应服务过程中，我们接入的SDK可能涉及收集您的个人信息，详见《隐私政策》第一条第（二）款SDK明细表。
  <br />（4）如您选择参与我们与合作机构联合开展的活动、福利、优惠、抽奖或类似活动时，我们可能与其共享参与活动人员的基本信息、活动过程中产生的、为完成活动所必须的信息，以便第三方能及时向您发放奖品或为您提供服务，我们会依据法律法规或国家标准的要求，在活动交互页面或通过其他途径向您明确告知需要向第三方提供何种信息。
  <br />（5）事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。
  <br />（6）履行我们在本《隐私政策》或我们与您达成的其他协议（包括在线签署的电子协议以及相应的平台规则）中的义务、约定和行使我们的权利；
  <br />我们仅会出于合法、正当、必要原则共享您的信息。对我们与之共享信息的公司、组织和个人，我们会与其签署严格的保密约定，要求他们在约定范围内合规的使用并就该等信息进行保密，未经您的授权不得向任何第三方提供。
  <br />（二）转让
  <br /> 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
  <br /> （1）事先获得您的明确同意；
  <br />（2）根据法律法规或强制性的行政或司法要求；
  <br />（3）在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会提前以公告的方式向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。
  <br />（三）披露
  <br /> 我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：
  <br />（1）根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
  <br />（2）根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
  <br />（四）根据相关法律法规规定，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息而需征得您的同意：
  <br />（1）与国家安全、国防安全有关的；
  <br />（2）与公共安全、公共卫生、重大公共利益有关的；
  <br />（3）与犯罪侦查、起诉、审判和判决执行等有关的；
  <br />（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
  <br />（5）所收集的信息是您自行向社会公众公开或我们可以从公开区域自行获取的；
  <br />（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
  <br />（7）法律法规规定的其他情形。
  <br /> 第三方提供的贷款产品/服务；以及我们认为可能与您相关或您正在搜寻的其他产品或服务。
 <h2>三、我们如何保留、储存和保护信息</h2>
 <br /> 我们仅在本《隐私政策》所述目的、服务所必需期间和法律法规要求的时限内保留、存储您的信息。
 <br /> 我们在中华人民共和国境内运营中收集和产生的信息，仅存储于中国境内。  <br /> 我们将采取以下手段保护您的信息：
 <br /> （一）数据安全技术措施
 <br />我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。
 <br /> 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。
 <br /> （二）我们为保护信息采取的其他安全措施
 <br /> 我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。
 <br /> 我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
 <br /> 加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。
 <br /> 任何获取您数据的合作方，我们都将与之签订严格的保密条款，并限定使用用途。
 <br /> （三）我们仅允许有必要知晓这些信息的我们员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。
 <br />（四）我们会采取一切合理可行的措施，确保未收集无关的信息。
 <br />（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。
 <br /> （六）互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
 <br /> （七）安全事件处置
 <br /> 在通过我们服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
 <br /> 为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
 <br /> 在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
 <br /> 请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入我们服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
 <h2> 四、如何管理您的信息</h2>
 <br />（一）查询/访问、更正和删除
 <br />我们鼓励您更正、补充您的相关信息以使其更准确有效，您可以通过APP页面（路径1：我的—个人资料）、或通过本协议联系邮箱（路径2：需求描述并邮件发送至本协议邮箱），我们将在收到您申请之日起的15天内完成身份验证及处理；我们将最大程度保证您查询/访问、更正、删除的权利。
 <br />（二）撤回/撤销
 <br />（1）对于您所作出的同意我们收集您个人信息的授权或者有其他特别情况需要撤销相关授权的，为保障您的权益，您可以第一时间通过以下途径、方式与我们取得联系进行撤回/撤销：您使用注册的手机号码直接拨打我们统一客服电话（客服电话：010 6493 5351）进行授权撤回/撤销操作，我们将在收到您申请之日起的15天内完成身份验证及处理，届时未经您再次授权同意，我们将不会再收集/获取您的个人信息，若您正在申请平台产品或服务，有可能导致申请产品/服务失败或错误，请知悉并自行承担该等风险。
 <br />（2）撤回授权
 <br /> 您可以通过手机设置—应用—权限管理，对相应功能权限进行关闭。
 <br />（三）注销
 <br /> 若您希望注销账户，在结清全部款项后，您可以通过本协议邮箱进行操作（路径：注销需求—发送邮件到指定的本协议邮箱）或通过APP进行操作（路径：我的-注销），我们将在收到您申请之日起的15天内完成身份验证及处理。当帐号注销后，与该帐号相关的全部服务资料和数据除法律法规规定必须保留的外都将被清空且不可恢复，请谨慎使用。
 <br /> （四）尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：
 <br />（1）与国家安全、国防安全直接相关的；
 <br />（2）与公共安全、公共卫生、重大公共利益直接相关的；
 <br />（3）与犯罪侦查、起诉、审判和执行判决等直接相关的；
 <br />（4）有充分证据表明您存在主观恶意或滥用权利的；
 <br />（5）响应您的请求将导致其他个人、组织的合法权益受到严重损害的；
 <br />（6）涉及商业秘密的。
 <h2> 五、第三方贷款产品/服务</h2>
 <br />我们平台上的产品/服务都由合作第三方提供，我们平台仅作导流推荐服务。包括：
 <br />（1）我们展示合作方的产品及申请要求，您按照产品要求进行信息填列、授权，并委托我们将您申请产品/服务所需的全部信息传输/共享给合作机构进行产品/服务的线上申请，后续流程由合作方负责对接并完成。
 <br />（2）我们通过广告或我们服务的其他方式向您提供合作方的H5链接，使您可以便捷的跳转到合作机构的页面、APP或网站，并根据合作机构的隐私政策、产品申请要求完成信息填列和产品/服务申请；及
 <br />（3）其他类似形式接入第三方服务的情形。
 <br />合作方的H5链接和其他由合作方负责的网站、APP等形式，由相关的第三方负责运营。您使用该等第三方的服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及隐私政策（而非本《隐私政策》）约束，您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的信息情况，并不适用于任何第三方所提供的H5页面、APP、网站的信息收集情况，您需审慎了解并提供，并因此承担相应的责任。
 <h2> 六、年龄限制</h2>
 <br />根据中华人民共和国相关法律法规规章等的规定，我们所提供的产品/服务仅供成年人使用，且不对学生开放，请您在使用相关产品/服务前确保您是年满18周岁（含）且不在上述限制人群范围内。若您属于上述人群，请自觉停止注册、使用任何产品/服务。
  <h2>七、通知和修订</h2>
  <br />我们可能适时修改本《隐私政策》的条款，并公布在我们官网/APP或通过弹窗的形式另行确认等，任何对单项条款的修改皆构成本《隐私政策》的一部分。对于重大变更，我们会提供相关通知或另行弹窗确认，您可以选择停止使用我们服务；在该种情况下，如您仍然继续使用我们服务的，即表示同意受经修订的本《隐私政策》的约束。
  <br />任何修改都会将您的满意度置于首位。我们鼓励您在每次使用我们服务时都查阅我们的隐私政策。
  <br /> 我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
  <br /> 最后，您必须对您的账号和密码信息负有保密义务。该等信息将视同为您在我们所有的任何平台的身份验证信息，任何情况下，请小心妥善保管。
 <h2> 八、其他</h2>
 <br />（一）运营者基本情况
 <br /><span style={{fontWeight:"bold",color:"red"}} >（1）公司名称： 灵川县裕丰小额贷款股份有限公司</span>
 <br /><span style={{fontWeight:"bold",color:"red"}} >灵川县八里街纬四路（阳光美地）6幢4单元2层201号房</span>
 <br /><span style={{fontWeight:"bold",color:"red"}} >联系方式：yufengxiaoe@163.com</span>
 <br />（二）我们产品/服务项下的全部数据均存储于国内，不会向境外传输任何数据；所有数据原则上自授权之日起存储五年，超期后处理方式（1）该等数据若需继续存储，则需获取用户的重新授权；（2）没有授权，则进行脱敏或删除处理。
  <h2>九、如何联系我们</h2>
  <br /> 如您有关于网络信息安全的投诉和举报，或您对本《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关本声明或隐私措施的问题，请将问题发送到 yunnanhonge@163.com 或邮寄到前述地址，我们将尽快审核所涉问题，并在验证您的用户身份后十五天内予以回复。
  <br />更新时间： 2024年01月15日
  <br />生效时间： 2024年01月15日
  
        </p>
      </div>
    </div>
  )
}

export default PrivacyYJJQFXDXM
import bg_oppo from './big_bg_oppo.png'
import logo from './logo7.png'
import './App.css'
import React from 'react'

function Main () {
  return (
    <div className="App">
      <div className='App-header'>
        <img src={logo} className="App-logo" alt="logo" />
        <div className='down-box'>
          <div className='down-box-a'>
            <a href='http://47.109.70.173/yj.apk'>下载app</a>
          </div>

          <a className='down-box-a' href='http://47.109.70.173/ay.apk'>下载app</a>
          {/* <a className='down-box-a' href='http://47.109.106.247/app-xiaoyi.apk'>下载app</a> */}
        </div>
        <span style={{ marginLeft: '500px', marginRight: '20px' }}>首页</span>

      </div>
      <img src={bg_oppo} className="App-bg" alt="logo" />

      <div className='App-footer'>
        <div> <a href='https://beian.miit.gov.cn/#/Integrated/index'>2023|桂ICP备2024018782号-16｜灵川县裕丰小额贷款股份有限公司</a></div>
        <div>资金来源：贷款资金由《灵川县裕丰小额贷款股份有限公司》联合银行金融机构联合提供</div>
      </div>

    </div>
  )
}

export default Main
import bg from '../src/big_bg.png'
import logo from '../src/logo.png'
import './App.css'
import React from 'react'

function Main () {
  return (
    <div className="App">
      <div className='App-header-xj'>
        <img src={logo} className="App-logo-xj" alt="logo" />

        <span style={{ marginLeft: '800px', marginRight: '20px' }}>首页</span>
        <a className='down-box-a' href='http://47.109.70.173/app-jietiaojieqiaanhua.apk'>下载app</a>
      </div>
      <img src={bg} className="App-bg" alt="logo" />
      <div className='App-footer'>
        <div> <a href='https://beian.miit.gov.cn/#/Integrated/index'>2023|桂ICP备2023014712号-1｜南宁市同正旭宁小额贷款有限公司</a></div>
        <div>资金来源：贷款资金由《南宁市同正旭宁小额贷款有限公司》联合银行金融机构联合提供</div>
      </div>
    </div>
  )
}

export default Main